.sensor {
  margin-top: 1rem;
}

.sensor-fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;

  & > .MuiFormControl-root {
    margin-top: 0.5rem;
  }
}
