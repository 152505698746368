@import '../../../assets/style/variables/colors.scss';

.tab-layout {
  background-color: $primary;
  border-radius: 0.25rem 0.25rem 0 0;

  .tab {
    color: $white;
    opacity: 0.5;
    padding: 1rem 2rem;

    &.Mui-selected {
      color: $white;
      opacity: 1;
    }
  }

  .MuiTabs-indicator {
    background-color: $white;
  }
}
