.page-section {
  padding: 1.5rem;
  margin-bottom: 1.5rem;

  .page-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page-section-title {
      padding: 0;
      margin: 0;
    }

    .actions-container {
      display: flex;
      gap: 0.5rem;
    }
  }
}
