@import '../../assets/style/variables/colors.scss';

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  .back-button {
    margin-right: 1rem;
  }

  .page-title {
    display: flex;
    padding: 0;
    margin: 0;
    gap: 0.5rem;
  }

  .search-input {
    max-width: 20rem;
    margin: 0;
    background-color: $white;

    .MuiInputBase-root {
      padding-right: 0;
    }
  }

  .actions-container {
    display: flex;
    gap: 0.5rem;
  }

  .filter-icon.active {
    color: $primary;
  }
}
