@import '../../assets/style/variables/colors.scss';

.profile-details-form {
  .profile-details-form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1rem;

    .profile-picture-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .profile-picture {
        width: 6rem;
        height: 6rem;
        background: $primary;

        .profile-picture-fallback {
          width: 3rem;
          height: 3rem;
        }
      }

      .profile-picture-error-container {
        margin-top: 0.5rem;
      }

      .profile-picture-button-container {
        display: flex;
        gap: 0.5rem;
        margin-top: 1rem;

        .profile-picture-button {
          min-width: 7rem;
        }
      }
    }

    .divider {
      margin: 0 2rem;
    }

    .profile-details-container {
      flex: 2;
    }
  }
}
