.type {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  text-align: center;
}

.transposition-values {
  display: flex;
  justify-content: space-evenly;

  .sensor-type {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .field {
    margin: 0.125rem 0;

    .value {
      margin-left: 0.125rem;
    }
  }
}
