@import '../../../assets/style/variables/colors.scss';

.operation-mode {
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;

  .dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;

    &.offline {
      background-color: $offline;
    }

    &.idle {
      background-color: $primary;
    }

    &.waiting_for_response,
    &.scheduled_to_listen,
    &.scheduled_to_stream {
      background-color: $idle;
    }

    &.alert {
      background-color: $error;
    }

    &.streaming,
    &.record_streaming,
    &.record_measuring,
    &.transferring,
    &.online {
      background-color: $online;
    }
  }
}
