@import '../../assets/style/variables/colors.scss';

.date-interval-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .error fieldset {
    border-color: $error;
  }

  &.creation {
    margin: 1rem 0 1.5rem 0;
  }

  .divider {
    color: $grey;
  }
}
