.graph-title .MuiAccordionSummary-content {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  gap: 0.5rem;

  button.download-sensor-csv {
    display: flex;
    min-width: 0;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;

    .MuiButton-startIcon {
      margin: 0;
    }
  }
}

.MuiAccordionSummary-expandIconWrapper svg {
  font-size: 1rem;
  transform: rotateZ(90deg);
}

.eda-view-page {
  .page-header {
    display: block;
  }
  .page-header-subtitles {
    margin-bottom: 1.5rem;
  }
}
