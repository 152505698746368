.device-time-wrapper {
  display: flex;
  gap: 1.5rem;

  button {
    display: flex;
    min-width: 0;
    padding: 1rem;
    margin: 1rem 0 0.5rem 0;

    .MuiButton-startIcon {
      margin: 0;
    }
  }
}
