@import '../../../../assets/style/variables/colors.scss';

.scheduling-control-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  > div {
    flex: 1;
  }
}

.scheduling-status {
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;

  .dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;

    &.SCHEDULED {
      background-color: $online;
    }

    &.SCHEDULED_TO_LISTEN,
    &.SCHEDULED_TO_STREAM {
      background-color: $idle;
    }

    &.NOT_SCHEDULED {
      background-color: $error;
    }

  }
}
