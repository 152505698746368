@import '../../../../assets/style/variables/colors.scss';

.sensor-container {
  display: flex;
  margin-bottom: 1rem;

  .multiselect-container {
    margin-right: 1rem;
  }

  .streaming-control-container {
    display: flex;
    gap: 1rem;

    .streaming-control {
      line-height: initial;
    }
  }
}

.chart-container > * {
  padding: 2rem;
}

.offline-warning {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  color: $error;
}
