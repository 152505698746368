$error: #d32f2f;
$main: #f5f5f5;
$primary: #1976d2;
$login-icon: #9c27b0;

$grey: #7e7e7e;
$light-grey: #d3d3d3;
$white: #ffffff;

$offline: #aaaaaa;
$idle: #ffdd22;
$online: #00dd00;
