.modal {
  .modal-actions-container {
    justify-content: space-between;
  }
}

.filter-modal .MuiPaper-root {
  min-width: 40rem;

  .MuiDialogTitle-root {
    font-size: 1.5rem;
  }
}

.calibration-modal .MuiPaper-root {
  min-width: 25rem;

  .confirm-text {
    text-align: center;
  }
}

.eda-generation-modal .MuiPaper-root {
  min-width: 40rem;
}

.calibration-modal .MuiPaper-root,
.eda-generation-modal .MuiPaper-root {
  .MuiDialogTitle-root {
    font-size: 1.5rem;
    text-align: center;
  }

  @import '../../assets/style/variables/colors.scss';

  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 3rem;
  }

  .modal-actions-container {
    justify-content: center;
  }

  .eda-generation-message {
    text-align: center;
    padding-top: 1rem;
    .view-eda-link {
      text-decoration: none;
      color: $primary;
    }
  }
}
