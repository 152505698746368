@import '../../assets/style/variables/colors.scss';

.table-wrapper {
  border: 1px solid $light-grey;
  border-radius: 0.25rem;

  .table-container {
    border-radius: 0.25rem 0.25rem 0 0;

    .table-header-row {
      background-color: $primary;

      th {
        cursor: default;
      }

      th,
      th span,
      th > span > svg.MuiTableSortLabel-icon {
        color: $white;
      }
    }

    .table-body tr {
      height: 4rem;
      background: $white;

      svg {
        color: $grey;
      }

      .action-icon {
        cursor: pointer;

        &.disabled {
          opacity: 0.25;
          pointer-events: none;
        }
      }
    }

    .editable-table-row .time-picker.error fieldset {
      border-color: $error;
    }

    .table-row,
    .editable-table-row {
      .table-cell {
        padding: 0 1rem;
      }

      .MuiTextField-root {
        margin: 0.5rem 0;
      }

      .MuiInputBase-input {
        padding: 0.5rem 1rem;
      }
    }

    .samplingRate-cell {
      width: 25rem;
    }

    .active-cell {
      text-align: center;
    }

    .actions-container {
      display: flex;
      gap: 1rem;
    }

    .head-actions-container {
      text-align: right;

      .add-button {
        color: $white;
        margin-right: 0.5rem;
      }
    }

    .no-result-message {
      text-align: center;
    }
  }

  .table-pagination {
    background: $white;
    border-radius: 0 0 0.25rem 0.25rem;
  }
}
