.time-series-graph,
.fft-graph {
  position: relative;
  width: 100% !important;
  height: 400px !important;

  .overlay {
    position: absolute;
    right: 0;

    .indicator {
      position: absolute;
      visibility: hidden;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: rgba(0, 100, 200, 0.25);
      border-left: 1px solid rgba(0, 100, 200, 0.5);
      border-right: 1px solid rgba(0, 100, 200, 0.5);
    }
  }
}
