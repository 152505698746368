@import '../../assets/style/variables/colors.scss';

.login {
  margin-top: 4rem;

  .header {
    .icon {
      margin: 0.5rem auto;
      background-color: $login-icon;
    }

    .title {
      text-align: center;
    }
  }

  .login-form {
    .submit-btn {
      margin: 1rem 0;
    }

    .login-error {
      text-align: center;
    }
  }

  .forgot-password {
    text-align: center;
  }
}
